"use strict";

$(document).ready(function ($) {
  var mobileBtn = $("#mobile-burger-btn");
  var mobileBtnInner = $(".menu-link");
  var pageBody = $("#page-wrap-inner");

  mobileBtn.bigSlide({
    menuWidth: "100%"
  });

  mobileBtn.click(function () {
    if (mobileBtnInner.hasClass("menu-open") == true) {
      pageBody.removeClass("push");
      mobileBtnInner.removeClass("menu-open");
    } else {
      pageBody.addClass("push");
      mobileBtnInner.addClass("menu-open");
    }
  });

  $("#trigger-menu-load").click(function () {
    mobileBtn.trigger("click");
  });

  $("#small-menu-load").click(function () {
    mobileBtn.trigger("click");
  });

  if ($("#project_imgs").length > 0) {}

  if ($(".gallery_js").length > 0) {
    var slideshows = $(".cycle-slideshow").on("cycle-next cycle-prev", function (e, opts) {
      // advance the other slideshow
      slideshows.not(this).cycle("goto", opts.currSlide);
    });

    $("#cycle-2 .cycle-slide").click(function () {
      var index = $("#cycle-2").data("cycle.API").getSlideIndex(this);
      slideshows.cycle("goto", index);
    });
  }

  $("#fullpage").fullpage({
    slidesNavigation: true,
    controlArrows: false,
    onLeave: function onLeave(index, newIndex, direction) {
      var section = $(".section").eq(index - 1);
      if (index == 3 && direction == "down") {
        section.removeClass("moveDown").addClass("moveUp");
      } else if (index == 3 && direction == "up") {
        section.removeClass("moveUp").addClass("moveDown");
      }
    }

  });

  $("#datetimepicker").datetimepicker({
    format: "d.m.Y H:i",
    inline: true,
    scrollMonth: false,
    scrollInput: false,
    scrollTime: false,
    timepickerScrollbar: false,
    yearStart: new Date().getFullYear(),
    yearEnd: new Date().getFullYear() + 1,
    lang: "ru",
    allowTimes: ["09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00"]
  });
});

$('.next_slide').click(function () {
  $.fn.fullpage.moveSectionDown();
});

$('.prev_slide').click(function () {
  $.fn.fullpage.moveSectionUp();
});

$(document).on("ready", function () {
  $(".regular").slick({
    dots: true,
    infinite: true,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: $(".next"),
    prevArrow: $(".prev"),
    appendDots: $(".testimonial-pagination"),
    dotsClass: "dots"
  });

  $(".maingallery").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: $(".kitchen-next"),
    prevArrow: $(".kitchen-prev"),
    autoplay: false,
    fade: true
  });
  $(".maingallery-nav").slick({
    slidesToShow: 7,
    slidesToScroll: 1,
    asNavFor: ".maingallery",
    dots: false,
    arrows: false,
    autoplay: false,
    centerMode: true,
    focusOnSelect: true
  });

  $("[data-fancybox]").fancybox({
    touch: {
      vertical: false
    }
  });

  $('[data-fancybox="appointment"], [data-fancybox="appointment2"]').fancybox({
    touch: {
      vertical: false
    },
    afterShow: function afterShow(instance, current) {
      var form = $("#appointment form");
      $("#appointment .submitbutton").click(function (e) {
        var formData = form.serialize();
        e.preventDefault();
        $.ajax({
          type: "POST",
          url: form.attr("action"),
          data: formData
        }).done(function (response) {
          if (response.success == true) {
            var html = "";
            html += '<div id="validation-msg" class="validation-msg">';
            html += "<h4>Thank-you for contacting Robinson Interiors.</h4>";
            html += "<p>Please note that your appointment is not confirmed until a member of our team contacts you with confirmation.</p>";
            html += "</div>";
            $("#appointment #frm-items-wrap").fadeOut().remove();
          } else {
            var html = "";
            html += '<div id="validation-msg" class="validation-msg">';
            html += "There was a problem submitting your form.  Please try again.";
            html += "</div>";
          }
          $(html).insertBefore(form);
        });
      });
    }
  });
});